<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentschemaselection'}"></work-subject>
        <hr>
        <h3>Tedavi Şema Seçimi Komponenti</h3>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentschemaselection.png" width="100%">
        </div>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.selected_schema">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'TreatmentSchemaSystem'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'TreatmentSchemaUser'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentShemaList',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_selectedSchema: {
        'schema': '',
        'schema_group': '',
        'schema_type': '',
      },
      d_createTableSqlExample: 'CREATE TABLE PatientBsa (PatientId int, weight_kg float, weight_lbs float, ideal_weight float, adjusted_weight float, height_inch float, height_cm float, bmi float, bsa float,bsa_date datetime );',
      d_vyaSqlExample: 'SELECT * FROM PatientBsa WHERE PatientId = 12345',
      d_componentExample: {
        'selected_schema': [
          { 'code': '<schema-selection :p_selectedSchema="d_selectedSchema"></schema-selection>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
      patient_data: {
        'sex': '0', // 0 erkek, 1 kadın
        'age': 55,
        'color': 1,
        'last_laboratory': {
          'creatinine': {
            'date': '2022-01-01T09:00:00.000000',
            'val': 1.2
          }
        },
        'last_bsa_data': {
          'date': '2022-01-01T09:00:00.000000',
          'bmi': 22,
          'ideal_weight': 60,
          'adjusted_weight': 70,
          'weight': 75,
          'bsa': 1.70,
          'length': 174
        }
      }
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_getSchemaDetails: function (schema_id) {
      console.log("f_getSchemaDetails START ...");
      console.log('schema_id : ', schema_id);
    }
  },
  watch: {
    'd_selectedSchema.schema': function () {
      console.log('watch schema selection : ', this.d_selectedSchema.schema);
      if (this.d_selectedSchema.schema && this.d_selectedSchema.schema.value) {
        this.f_getSchemaDetails(this.d_selectedSchema.schema.value);
      } else {
        this.d_selectedSchema.schema = '';
      }
    },
    'd_selectedSchema.schema_group': function () {
      console.log('watch schema_group selection : ', this.d_selectedSchema.schema_group);
      if (!this.d_selectedSchema.schema_group) {
        this.d_selectedSchema.schema_group = '';
      }
    },
    'd_selectedSchema.schema_type': function () {
      console.log('watch schema_type selection : ', this.d_selectedSchema.schema_type);
      if (!this.d_selectedSchema.schema_type) {
        this.d_selectedSchema.schema_type = '';
      }
    }
  }
}

</script>

<style type="text/css">


</style>

